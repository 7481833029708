import {Controller} from "@hotwired/stimulus"

import tinymce from '~/libs/tinymce';

// Connects to data-controller="tinymce"
export default class extends Controller {
  static targets = ['input']

  initialize() {
    this.defaults = {
      forced_root_block: 'div',
      toolbar_sticky: true, // Menu bar is fixed when you scroll inside text editor
      skin: false, // Fix 404
      content_css: false, // Fix 404
      menubar: true,
      link_default_target: "_blank", // Anchor option defaults to 'New Window'
      plugins: 'link lists fullscreen preview table code wordcount',
      toolbar: [
        'styleselect | bold italic underline strikethrough superscript | blockquote numlist bullist link | alignleft aligncenter alignright | table',
        'undo redo | fullscreen preview code help'
      ],
      mobile: {
        toolbar: [
          'styleselect | bold italic underline strikethrough superscript',
          'blockquote numlist bullist link | alignleft aligncenter alignright | table',
          'undo redo | fullscreen preview code help'
        ]
      },
      // style_formats: [
      //   { title: 'Heading 1', format: 'h1' },
      //   { title: 'Heading 2', format: 'h2' },
      //   { title: 'Heading 3', format: 'h3' },
      //   { title: 'Heading 4', format: 'h4' },
      //   { title: 'Heading 5', format: 'h5' },
      //   { title: 'Heading 6', format: 'h6' },
      //   { title: 'Paragraph', format: 'p'}
      // ],
      // max_height: 700,
      // default_link_target: '_blank',
      // link_title: false,
      // autoresize_bottom_margin: 10,
      // link_context_toolbar: true,
      // relative_urls: false,
      // browser_spellcheck: true,
      // element_format: 'html',
      // invalid_elements: ['span'],
      // content_style: 'html { font-family: Roboto, sans-serif; line-height: 1.5; }'
    }
  }

  inputTargetConnected(target) {
    tinymce.init(Object.assign({target: target}, this.defaults))
  }

  disconnect() {
    if (!this.preview) tinymce.remove()
  }

  get preview() {
    return (
      document.documentElement.hasAttribute('data-turbolinks-preview') ||
      document.documentElement.hasAttribute('data-turbo-preview')
    )
  }
}